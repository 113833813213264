export const DefaultCreators = [{ value: "a" }];

export enum RecordType {
  BOOK = "BOOK",
  DIGITAL_BOOK = "DIGITAL_BOOK",
  PROCEEDINGS = "PROCEEDINGS",
  PERIODICAL = "PERIODICAL",
  ARTICAL_PERIODICAL = "ARTICAL_PERIODICAL",
  ELECTRONIC_DOCUMENT = "ELECTRONIC_DOCUMENT",
  INTERNET_PORTAL = "INTERNET_PORTAL",
  WEBSITE = "WEBSITE",
  CONTRIBUTION_INTERNET_PORTAL = "CONTRIBUTION_INTERNET_PORTAL",
  FILM = "FILM",
  SERIAL_SHOW = "SERIAL_SHOW",
  POST_IN_SHOW = "POST_IN_SHOW",
  GRAPHIC_WORK = "GRAPHIC_WORK",
  REPRODUCTION_GRAPHIC_WORK = "REPRODUCTION_GRAPHIC_WORK",
  MUSIC = "MUSIC",
  QUALIFICATION_WORK = "QUALIFICATION_WORK",
  MAPS = "MAPS",
}

export enum RecordItem {
  CREATOR = "100a", // Jméno tvůrce
  ANOTHER_CREATOR = "700a", // Další tvůrce
  NAME = "245a", // Název
  PERIODICAL_NAME = "245a", // nazev periodika
  SUBNAME = "245b", // podnázev
  SECONDARY_NAME = "SECONDARY_NAME", // Vedlejší název
  EDITION = "250a", // Vydání
  PLACE_PUBLICATION = "264a", // Místo publikování
  PUBLISHER = "264b", // Nakladatel
  DATE_PUBLICATION = "264c", // Datum publikování
  DATE_INTRODUCTION = "DATE_INTRODUCTION", // Datum uvedení v zemi produkce
  EDITION_NAME_AND_NUMBERING = "490a", // Název edice a číslování
  STANDARD_IDENTIFIER = "STANDARD_IDENTIFIER", // Standardní identifikátor
  ISBN = "020a",
  ISSN = "022a",
  NOTES = "NOTES", // Poznámky
  AVAILABILITY_AND_ACCESS = "856u", // Dostupnost a přístup (Dostupné z)
  PARENT_CREATOR = "PARENT_CREATOR", // Jméno tvůrce mateřského dokumentu
  PARENT_NAME = "773t", // Název mateřského dokumentu / Název periodika (u článku v periodiku) / Název webového sídla (u webové stránky)
  PARENT_SUBNAME = "PARENT_SUBNAME", // Podnázev webového sídla (u webové stránky nebo u příspěvku do webového sídla)
  PARENT_ANOTHER_CREATOR = "PARENT_ANOTHER_CREATOR", // Další tvůrce mateřského dokumentu
  PARENT_NUMBERING = "PARENT_NUMBERING", // Číslování svazku obsahující grafické dílo
  NUMBERING_OF_VOLUME = "NUMBERING_OF_VOLUME", // Číslování svazku obsahujícího (příspěvek / grafické dílo)
  RANGE_OF_PAGES = "773g", // Rozsah stran (příspěvku / grafického díla)
  CARRIER_TYPE = "245h", // Typ nosiče
  NUMBERING = "NUMBERING", // Číslování
  LOCATION = "910a", // Lokace
  DATE_CITATION = "DATE_CITATION", // Datum citování
  DATE_UPDATE = "DATE_UPDATE", // Datum aktualizace/revize
  SYSTEM_REQUIREMENTS = "SYSTEM_REQUIREMENTS", // Požadavky na systém
  SERIES_NUMBER_EPISODE_NUMBER = "SERIES_NUMBER_EPISODE_NUMBER", // Číslo řady, číslo epizody
  EPISODE_NAME = "EPISODE_NAME", // Název epizody
  BROADCASTING_TYPE = "BROADCASTING_TYPE", // Druh vysílání
  STATION_NAME_AND_DATE_INTRODUCTION = "STATION_NAME_AND_DATE_INTRODUCTION", // Název stanice a datum uvedení v zemi produkce
  ALTERNATIVE_NAME = "ALTERNATIVE_NAME", // Alternativní název
  STORAGE_LOCATION = "STORAGE_LOCATION", // Místo uložení
  PLACE_SUBMISSION = "PLACE_SUBMISSION", // Místo odevzdání práce
  DATE_SUBMISSION = "DATE_SUBMISSION", // Datum odevzdání práce
  WORK_TYPE = "WORK_TYPE", // Druh práce
  INSTITUION_NAME = "INSTITUION_NAME", // Název instituce, kde byla práce napsána
  SUPERVISOR = "SUPERVISOR", // Vedoucí kvalifikační práce
  SCALE = "255a", // Měřítko
  DIMENSION = "300c", // Rozměr

  IN = "IN",
  DOT = "DOT",
  DOT_OPTIONAL = "DOT_OPTIONAL",
  COLON = "COLON",
  COLON_OPTIONAL = "COLON_OPTIONAL",
  COMMA = "COMMA",
  COMMA_OPTIONAL = "COMMA_OPTIONAL",
  BRACKET_LEFT = "BRACKET_LEFT",
  BRACKET_LEFT_OPTIONAL = "BRACKET_LEFT_OPTIONAL",
  BRACKET_RIGHT = "BRACKET_RIGHT",
  BRACKET_RIGHT_OPTIONAL = "BRACKET_RIGHT_OPTIONAL",
}

export const RecordTypes = [
  {
    value: RecordType.BOOK,
    label: "Kniha",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.DOT_OPTIONAL,
      RecordItem.EDITION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.DOT_OPTIONAL,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.DOT,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ISBN,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NOTES,
      RecordItem.DOT_OPTIONAL,
    ],
  },
  {
    value: RecordType.DIGITAL_BOOK,
    label: "Digitalizovaná kniha",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.DOT_OPTIONAL,
      RecordItem.EDITION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.DOT_OPTIONAL,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ISBN,
      RecordItem.DOT_OPTIONAL,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NOTES,
      RecordItem.DOT_OPTIONAL,
    ],
  },
  {
    value: RecordType.PROCEEDINGS,
    label: "Příspěvek ve sborníku",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.DOT,
      RecordItem.IN,
      RecordItem.PARENT_CREATOR,
      RecordItem.PARENT_NAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.EDITION,
      RecordItem.PARENT_ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.COMMA_OPTIONAL,
      RecordItem.NUMBERING_OF_VOLUME,
      RecordItem.COMMA_OPTIONAL,
      RecordItem.RANGE_OF_PAGES,
      RecordItem.COMMA_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ISBN,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ISSN,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NOTES,
      RecordItem.DOT_OPTIONAL,
    ],
  },
  {
    value: RecordType.PERIODICAL,
    label: "Periodikum (seriálová publikace)",
    structure: [
      RecordItem.PERIODICAL_NAME,
      RecordItem.DOT_OPTIONAL,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT,
      RecordItem.EDITION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NUMBERING,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ISSN,
      RecordItem.DOT,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.DOT_OPTIONAL,
      RecordItem.LOCATION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NOTES,
    ],
    optional: [
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.LOCATION,
      RecordItem.CARRIER_TYPE,
      RecordItem.NUMBERING,
    ],
  },
  {
    value: RecordType.ARTICAL_PERIODICAL,
    label: "Příspěvek (článek) v periodiku",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.DOT,
      RecordItem.PARENT_NAME,
      RecordItem.DOT_OPTIONAL,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.EDITION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON_OPTIONAL,
      RecordItem.PUBLISHER,
      RecordItem.COMMA_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA,
      RecordItem.NUMBERING,
      RecordItem.COMMA,
      RecordItem.RANGE_OF_PAGES,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT,
      RecordItem.ISSN,
      RecordItem.DOT,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.DOT_OPTIONAL,
      RecordItem.LOCATION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NOTES,
    ],
    optional: [
      RecordItem.PARENT_NAME,
      RecordItem.SECONDARY_NAME,
      RecordItem.CARRIER_TYPE,
      RecordItem.PUBLISHER,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.DATE_CITATION,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.LOCATION,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.ELECTRONIC_DOCUMENT,
    label: "Elektronický dokument",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.DOT_OPTIONAL,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON_OPTIONAL,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA_OPTIONAL,
      RecordItem.DATE_UPDATE,
      RecordItem.BRACKET_LEFT_OPTIONAL,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT_OPTIONAL,
      RecordItem.DOT_OPTIONAL,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT_OPTIONAL,
      RecordItem.ISBN,
      RecordItem.ISSN,
      RecordItem.DOT_OPTIONAL,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.DOT_OPTIONAL,
      RecordItem.LOCATION,
      RecordItem.DOT_OPTIONAL,
      RecordItem.SYSTEM_REQUIREMENTS,
      RecordItem.DOT_OPTIONAL,
      RecordItem.NOTES,
      RecordItem.DOT_OPTIONAL,
    ],
    optional: [
      RecordItem.PLACE_PUBLICATION,
      RecordItem.SUBNAME,
      RecordItem.SECONDARY_NAME,
      RecordItem.CARRIER_TYPE,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.DATE_UPDATE,
      RecordItem.DATE_CITATION,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.NOTES,
      RecordItem.ISBN,
      RecordItem.ISSN,
      RecordItem.LOCATION,
      RecordItem.SYSTEM_REQUIREMENTS,
    ],
  },
  {
    value: RecordType.INTERNET_PORTAL,
    label: "Webové sídlo, internetový portál",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA,
      RecordItem.DATE_UPDATE,
      RecordItem.BRACKET_LEFT,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT,
      RecordItem.STANDARD_IDENTIFIER,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.LOCATION,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.WEBSITE,
    label: "Webová stránka",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.DOT,
      RecordItem.PARENT_NAME,
      RecordItem.PARENT_SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA,
      RecordItem.DATE_UPDATE,
      RecordItem.BRACKET_LEFT,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.STANDARD_IDENTIFIER,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.LOCATION,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.CONTRIBUTION_INTERNET_PORTAL,
    label: "Příspěvek do webového sídla",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.IN,
      RecordItem.PARENT_CREATOR,
      RecordItem.DOT,
      RecordItem.PARENT_NAME,
      RecordItem.PARENT_SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA,
      RecordItem.DATE_UPDATE,
      RecordItem.BRACKET_LEFT,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.STANDARD_IDENTIFIER,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.LOCATION,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.FILM,
    label: "Film",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_INTRODUCTION,
      RecordItem.DOT,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.SERIAL_SHOW,
    label: "Seriálový pořad",
    structure: [
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SERIES_NUMBER_EPISODE_NUMBER,
      RecordItem.COMMA,
      RecordItem.EPISODE_NAME,
      RecordItem.DOT,
      RecordItem.CREATOR, // TODO:  Další tvůrce?
      RecordItem.DOT,
      RecordItem.BROADCASTING_TYPE,
      RecordItem.COMMA,
      RecordItem.STATION_NAME_AND_DATE_INTRODUCTION,
      RecordItem.DOT,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.POST_IN_SHOW,
    label: "Příspěvek v pořadu",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.DOT,
      RecordItem.IN,
      RecordItem.PARENT_NAME,
      RecordItem.PARENT_SUBNAME,
      RecordItem.DOT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.BROADCASTING_TYPE,
      RecordItem.COMMA,
      RecordItem.STATION_NAME_AND_DATE_INTRODUCTION,
      RecordItem.DOT,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.GRAPHIC_WORK,
    label: "Grafické dílo jako samostatný objekt",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.ALTERNATIVE_NAME,
      RecordItem.BRACKET_RIGHT,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.STORAGE_LOCATION,
      RecordItem.DOT,
      RecordItem.LOCATION,
    ],
  },
  {
    value: RecordType.REPRODUCTION_GRAPHIC_WORK,
    label: "Reprodukce grafického díla jako součást jiného dokumentu",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.ALTERNATIVE_NAME,
      RecordItem.BRACKET_RIGHT,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.IN,
      RecordItem.PARENT_CREATOR,
      RecordItem.DOT,
      RecordItem.PARENT_NAME,
      RecordItem.PARENT_SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.DOT,
      RecordItem.EDITION,
      RecordItem.DOT,
      RecordItem.PARENT_ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.DOT,
      RecordItem.PARENT_NUMBERING,
      RecordItem.COMMA,
      RecordItem.RANGE_OF_PAGES,
      RecordItem.COMMA,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT,
      RecordItem.STANDARD_IDENTIFIER,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.MUSIC,
    label: "Hudba (hudební album)",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.EDITION,
      RecordItem.DOT,
      RecordItem.ANOTHER_CREATOR,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.COMMA,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.QUALIFICATION_WORK,
    label: "Kvalifikační práce",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SECONDARY_NAME,
      RecordItem.BRACKET_LEFT,
      RecordItem.CARRIER_TYPE,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.PLACE_SUBMISSION,
      RecordItem.COMMA,
      RecordItem.DATE_SUBMISSION,
      RecordItem.BRACKET_LEFT,
      RecordItem.DATE_CITATION,
      RecordItem.BRACKET_RIGHT,
      RecordItem.DOT,
      RecordItem.AVAILABILITY_AND_ACCESS,
      RecordItem.DOT,
      RecordItem.WORK_TYPE,
      RecordItem.DOT,
      RecordItem.INSTITUION_NAME,
      RecordItem.DOT,
      RecordItem.SUPERVISOR,
      RecordItem.NOTES,
    ],
  },
  {
    value: RecordType.MAPS,
    label: "Mapy",
    structure: [
      RecordItem.CREATOR,
      RecordItem.DOT,
      RecordItem.NAME,
      RecordItem.SUBNAME,
      RecordItem.DOT,
      RecordItem.SCALE,
      RecordItem.DOT,
      RecordItem.PLACE_PUBLICATION,
      RecordItem.COLON,
      RecordItem.PUBLISHER,
      RecordItem.COMMA,
      RecordItem.DATE_PUBLICATION,
      RecordItem.DOT,
      RecordItem.DIMENSION,
      RecordItem.EDITION_NAME_AND_NUMBERING,
      RecordItem.DOT,
      RecordItem.STANDARD_IDENTIFIER,
      RecordItem.NOTES,
    ],
  },
];
