import React, { Component } from "react";
import loadScript from "load-script";
import "./GoogleDrivePicker.css";
import Button from "@material-ui/core/Button";
import { Drive } from "../icons/Drive";

// https://www.npmjs.com/package/react-google-picker
const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";

declare global {
  interface Window {
    gapi: any;
    google: any;
  }
}

let scriptLoadingStarted = false;

interface GoogleDrivePickerProps {
  clientId: string;
  developerKey: string;
  onChange: Function;
  origin?: string;
  query?: string;
  mimeTypes?: string[];
  scope?: string[];
  viewId?: string;
  authImmediate?: boolean;
  onAuthenticate?: Function;
  onAuthFailed?: Function;
  createPicker?: Function;
  multiselect?: boolean;
  navHidden?: boolean;
  disabled?: boolean;
}

export class GoogleDrivePicker extends Component<GoogleDrivePickerProps> {
  static defaultProps = {
    onChange: () => {},
    onAuthenticate: () => {},
    onAuthFailed: () => {},
    scope: ["https://www.googleapis.com/auth/drive.readonly"],
    viewId: "DOCS",
    authImmediate: false,
    multiselect: false,
    navHidden: false,
    disabled: false,
  };
  componentDidMount() {
    if (this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, this.onApiLoad);
    } else {
      // is loading
    }
  }

  isGoogleReady() {
    return !!window.gapi;
  }

  isGoogleAuthReady() {
    return !!window.gapi.auth;
  }

  isGooglePickerReady() {
    return !!window.google.picker;
  }

  onApiLoad = () => {
    window.gapi.load("auth");
    window.gapi.load("picker");
  };

  doAuth = (callback: any) => {
    window.gapi.auth.authorize(
      {
        client_id: this.props.clientId, // eslint-disable-line @typescript-eslint/camelcase
        scope: this.props.scope,
        immediate: this.props.authImmediate,
      },
      callback
    );
  };

  onChoose = () => {
    if (
      !this.isGoogleReady() ||
      !this.isGoogleAuthReady() ||
      !this.isGooglePickerReady() ||
      this.props.disabled
    ) {
      return null;
    }

    const token = window.gapi.auth.getToken();
    const oauthToken = token && token.access_token;

    if (oauthToken) {
      this.createPicker(oauthToken);
    } else {
      this.doAuth((response: any) => {
        if (response.access_token) {
          this.createPicker(response.access_token);
        } else {
          this.props.onAuthFailed && this.props.onAuthFailed(response);
        }
      });
    }
  };

  createPicker = (oauthToken: string) => {
    this.props.onAuthenticate && this.props.onAuthenticate(oauthToken);

    if (this.props.createPicker) {
      return this.props.createPicker(window.google, oauthToken);
    }

    const googleViewId =
      this.props.viewId && window.google.picker.ViewId[this.props.viewId];
    const view = new window.google.picker.View(googleViewId);

    if (this.props.mimeTypes) {
      view.setMimeTypes(this.props.mimeTypes.join(","));
    }
    if (this.props.query) {
      view.setQuery(this.props.query);
    }

    if (!view) {
      throw new Error("Can't find view by viewId");
    }

    const picker = new window.google.picker.PickerBuilder()
      .addView(view)
      .setOAuthToken(oauthToken)
      .setDeveloperKey(this.props.developerKey)
      .setCallback(this.props.onChange);

    if (this.props.origin) {
      picker.setOrigin(this.props.origin);
    }

    if (this.props.navHidden) {
      picker.enableFeature(window.google.picker.Feature.NAV_HIDDEN);
    }

    if (this.props.multiselect) {
      picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
    }

    picker.build().setVisible(true);
  };

  render() {
    return (
      <Button type="button" onClick={this.onChoose}>
        Google Drive <Drive style={{ marginLeft: "5px", height: "30px" }} />
      </Button>
    );
  }
}
