import React from "react";
import Typography from "@material-ui/core/Typography";

export function SelectNoOptions(props: any) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}
